import React, { useState } from 'react';
import { confirmResetPassword, resetPassword } from 'aws-amplify/auth';
import { RotatingLines } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ArrowLeftCircleIcon } from '@heroicons/react/24/solid';


const ForgotPwd = () => {
    const { t} = useTranslation();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [password2, setPassword2] = useState('');
    const [code, setCode] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await confirmResetPassword({ username, confirmationCode: code, newPassword: password });
            setError(t('密码重新设置成功，请使用新密码登录。'));
            navigate('/login')
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    const handleSendCode = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            await resetPassword({ username });
            setError(t('验证码已发送，请检查您的电子邮件。'));
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="p-10 justify-center flex flex-col text-center dark:bg-black h-screen">
            <div className={'bg-blue-50 py-20 pt-10 px-10 w-1/2 rounded-xl mx-auto dark:bg-slate-900'}>
                <div className={'text-gray-500 w-full text-left'}>
                    <ArrowLeftCircleIcon className={'w-8 h-8'} onClick={() => navigate('/login')} aria-label={t('返回')}/>
                </div>
                <div className={'px-2 mb-10 w-1/2 rounded-xl mx-auto text-lg font-bold text-gray-500  dark:text-gray-300'}>
                    {t('忘记密码')}
                </div>
                <form onSubmit={handleSubmit} className={'mx-auto max-w-sm'}>
                    <input
                        type="email"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('邮箱地址')}
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <div className={'flex items-center mb-2 gap-5'}>
                        <input
                            type="number"
                            className="block border border-gray-400 p-2  rounded w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                            placeholder={t('验证码')}
                            value={code}
                            onChange={(e) => setCode(e.target.value)}
                        />
                        <button className={'whitespace-nowrap disabled:opacity-50 rounded bg-blue-500 text-white py-2 px-4 dark:bg-slate-700 md:dark:hover:bg-slate-600'}
                                disabled={loading || !username}
                                onClick={handleSendCode}
                        >
                            {t('获取验证码')}
                        </button>
                    </div>

                    <input
                        type="password"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('密码')}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <input
                        type="password"
                        className="block border border-gray-400 p-2 mb-2 rounded  w-full dark:text-white dark:bg-slate-700 dark:border-slate-700"
                        placeholder={t('确认密码')}
                        value={password2}
                        onChange={(e) => setPassword2(e.target.value)}
                    />
                    <button
                        disabled={loading || !code || !username || !password || !password2 || password !== password2}
                        type="submit"
                        className="bg-blue-500 text-white py-2 px-4 rounded w-full my-2 hover:bg-blue-600 h-12 justify-center flex items-center disabled:opacity-50 dark:bg-slate-700 md:dark:hover:bg-slate-600"
                    >
                        {loading ?
                            <RotatingLines
                                strokeColor="#fff"
                                strokeWidth="5"
                                animationDuration="0.75"
                                width="20"
                                wrapperClassName="mx-auto"
                                visible={true}
                            />
                            :
                            t('提交')
                        }
                    </button>
                </form>
            </div>

            {error && (
                <p className="text-red-500 text-sm mt-2">{error}</p>
            )}

        </div>
    );
};

export default ForgotPwd;

